import { api, i18n } from "@/config"
import { map, isEmpty, sumBy, transform } from "lodash-es"
import { normalizeSortingParams } from "@/helpers/vue-good-table"
import { handleUpdateItemsError } from "@/config/store/matching/shared"
import { showToast } from "@/helpers/toasts"
import { SHOPS_MAX_COUNT } from "@/config/constants"
import { currentTimestamp } from "../../StoreItemsModule/helpers"

const isTotalCountExceed = (items, itemsDeleted, pagination) => {
  const total = sumBy(items, item => Number(!isEmpty(item._addedAt))) + pagination.total_count - itemsDeleted.length

  return total > SHOPS_MAX_COUNT
}

const prepareShopsSourcesCarParams = ({ itemsAdded = [], itemsDeleted = [], itemsUpdated = [] }) => {
  return transform(
    {
      deleted: itemsDeleted,
      created: itemsAdded,
      updated: itemsUpdated
    },

    (result, items, key) => {
      if (items.length) {
        result[key] = map(items, ({ id, sources_cars }) => {
          return {
            id,
            sources_cars: map(sources_cars, ({ id, car_class }) => ({
              id,
              car_class: {
                id: car_class.id,
                name: car_class.name,
                dp: car_class.dp,
                sync: car_class.sync,
                inbound: car_class.inbound,
                sources_car_classes: map(car_class.sources_car_classes, ({ dp, id, name, ota_id }) => ({
                  dp,
                  id,
                  name,
                  ota_id
                }))
              }
            }))
          }
        })
      }
    },

    {}
  )
}

const prepareSourcesCars = cars => {
  if (!isEmpty(cars)) return cars

  const newCarsList = [
    {
      id: null,
      name: "",
      _addedAt: `t_${currentTimestamp()}`,
      car_class: {
        id: null,
        name: null,
        sources_car_classes: [],
        dp: false,
        inbound: false,
        immutable_ota_ids: [],
        sync: false
      }
    }
  ]

  return newCarsList
}

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit, state: { filters, pagination, sorting } }, customParams = {}) => {
    const params = {
      filters,
      pagination,
      sorting: normalizeSortingParams(sorting),
      ...customParams
    }

    const response = await api.get(baseURI, { params })
    const { items, meta } = response.data.data
    const custom_items = items.map(shop => ({
      ...shop,
      selected: !isEmpty(shop.sources_cars),
      sources_cars: prepareSourcesCars(shop.sources_cars),
      _addedAt: null
    }))

    const custom_meta_pagination = {
      ...meta.pagination,
      total_count: custom_items.length
    }

    commit("SET_ITEMS", custom_items)
    commit("SET_PAGINATION_DATA", custom_meta_pagination)
    commit("RESET_ITEMS_DELETED")
    commit("RESET_ITEMS_ADDED")
    commit("RESET_ITEMS_UPDATED")
  },

  FETCH_SELECTED_ITEMS: async ({ commit, state: { filters, pagination, sorting } }, customParams = {}) => {
    const params = {
      filters,
      pagination,
      sorting: normalizeSortingParams(sorting),
      selected: true,
      ...customParams
    }

    commit("SET_SELECTED_ITEMS_LOADING", true)
    try {
      const response = await api.get(baseURI, { params })
      const { items, meta } = response.data.data
      const selected_items = items.map(shop => ({
        ...shop,
        selected: !isEmpty(shop.sources_cars),
        sources_cars: prepareSourcesCars(shop.sources_cars),
        _addedAt: null
      }))

      const custom_meta_pagination = {
        ...meta.pagination,
        total_count: selected_items.filter(item => item.selected).length
      }

      commit("SET_SELECTED_ITEMS", selected_items)
      commit("SET_PAGINATION_DATA", custom_meta_pagination)
      commit("RESET_ITEMS_DELETED")
      commit("RESET_ITEMS_ADDED")
      commit("RESET_ITEMS_UPDATED")
    } finally {
      commit("SET_SELECTED_ITEMS_LOADING", false)
    }
  },

  UPDATE_ITEMS: async (
    { state: { itemsAdded, itemsDeleted, itemsUpdated, filters, pagination, sorting }, commit },
    customParams = {}
  ) => {
    const shops = prepareShopsSourcesCarParams({ itemsAdded, itemsDeleted, itemsUpdated })
    commit("SET_SELECTED_ITEMS_LOADING", true)
    try {
      if (isEmpty(shops)) return Promise.resolve()
      const params = { filters, pagination, sorting, ...customParams }

      const response = await api.put(baseURI, { shops: shops, ...params })
      const { items, meta } = response.data.data

      const custom_items = items.map(shop => ({
        ...shop,
        selected: !isEmpty(shop.sources_cars),
        sources_cars: prepareSourcesCars(shop.sources_cars),
        _addedAt: null
      }))

      const selected_items = custom_items.filter(shop => shop.selected)
      const custom_meta_pagination = {
        ...meta.pagination,
        total_count: selected_items.length
      }

      commit("SET_ITEMS", custom_items)
      commit("SET_SELECTED_ITEMS", selected_items)
      commit("SET_PAGINATION_DATA", custom_meta_pagination)
      commit("SET_INVALID_ITEM_IDS", [])
      commit("RESET_ITEMS_DELETED")
      commit("RESET_ITEMS_ADDED")
      commit("RESET_ITEMS_UPDATED")
    } catch (error) {
      handleUpdateItemsError({ error, commit, itemsUpdated, itemsDeleted })
    } finally {
      commit("SET_SELECTED_ITEMS_LOADING", false)
    }
  },

  ADD_ITEM({ state: { items, itemsDeleted, pagination }, commit }) {
    if (isTotalCountExceed(items, itemsDeleted, pagination)) {
      return showToast({ text: i18n.t("shop_matching.reach_max_limit_error", { num: SHOPS_MAX_COUNT }) })
    }

    const shop = {
      id: null,
      selected: false,
      name: "",
      sync: false,
      dp: false,
      external_ids: [],
      sources_cars: prepareSourcesCars([])
    }

    commit("ADD_ITEM", { item: shop, prepend: true })
  },

  UPDATE_ITEM_BY_INDEX({ commit }, { item, index }) {
    commit("SET_ITEM_BY_INDEX", { item, index })
  },

  APPLY_SEARCH({ commit, dispatch, state }, { search_value }) {
    commit("SET_PAGINATION_DATA", { ...state.pagination, current_page: 1 })
    commit("SET_FILTERS", { search_value })
    dispatch("FETCH_ITEMS", { ...state.filters })
  }
})
