import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import actionsBuild from "./actions"
import mutationsBuild from "./mutations"
import { commonGetters } from "../shared"

const BASE_URI = "/shops_sources_car_matching"
const store = new StoreItemsModule({
  baseURI: BASE_URI,
  withFilters: true,
  withPagination: true,
  withSorting: true
})

store.mergeState({
  invalidItemIds: [],
  selectedItems: [],
  selectedItemsLoading: false,
  itemsUpdated: []
})

store.mergeGetters(commonGetters)
store.mergeMutations(mutationsBuild())

const { FETCH_ITEMS, FETCH_SELECTED_ITEMS, ADD_ITEM, UPDATE_ITEMS, UPDATE_ITEM_BY_INDEX, APPLY_SEARCH } = actionsBuild({
  baseURI: BASE_URI
})

store.mergeActions({ ADD_ITEM, UPDATE_ITEM_BY_INDEX, APPLY_SEARCH })
store.mergeActions({ FETCH_ITEMS, FETCH_SELECTED_ITEMS, UPDATE_ITEMS }, withLoading)

export default store
